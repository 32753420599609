const refToDiv = Symbol();

export default {
  open(url) {
    const div = document.createElement("div");
    div.tabIndex = 0;
    div.className = "s-visualizar-imagem";
    div.innerHTML = `<i
    class="mdi mdi-close"
    aria-hidden="true"
  ></i><img src="${url}">`;

    div.querySelector("i").addEventListener(
      "click",
      () => this.close(),
      { once: true },
    );

    div.addEventListener("keydown", (e) => {
      if (e.code === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        this.close();
      }
    });
    document.body.appendChild(div);
    this[refToDiv] = div;
    div.focus();
  },
  close() {
    if (this[refToDiv]) {
      document.body.removeChild(this[refToDiv]);
      this[refToDiv] = null;
    }
  },
};
