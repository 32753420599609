<template>
  <div
    style="display: flex; flex-direction: column; height: 100%; position:relative"
    v-enter-next>
    <div>
      <button v-if="!row" type="button" @click="novo" class="btn-primary"
        style="position: absolute; right: 5px; top: 5px">
        Novo
      </button>
    </div>
    <s-data-table :columns="columns" :items="value" item-key="id"
      style="flex: 1; height: 100%;">
      <template #item="{ item, column, index }">
        <div v-if="column.value === 'centroCusto'">
          <s-foreigninput v-if="row?.id == item.id"
            v-model="row['centroCusto.cencusCod']"
            :url="`${basePath}/industrial/centro-custo/get-by-id`" :tela="247"
            :number="true" field-cod="cencusCod" field-des="cencusDes"
            @change="(cc) => row['centroCusto.cencusDes'] = cc?.cencusDes || ''"></s-foreigninput>
          <span v-else>
            {{ item['centroCusto.cencusCod'] }} -
            {{ item['centroCusto.cencusDes'] }}
          </span>
        </div>

        <div v-else-if="column.value === 'pessoa'">
          <div v-if="isReceber">
            <s-foreigninput v-if="row?.id == item.id"
              v-model="row['cliente.cliCod']"
              :url="`${basePath}/app/clientes/get-by-id`" :tela="47"
              :number="true" field-cod="cliCod" field-des="pessoa.nome"
              @change="(f) => row['cliente.pessoa.nome'] = f?.pessoa?.nome || ''"></s-foreigninput>
            <span v-else>
              {{ item['cliente.cliCod'] }} -
              {{ item['cliente.pessoa.nome'] }}
            </span>
          </div>
          <div v-else-if="isPagar">
            <s-foreigninput v-if="row?.id == item.id"
              v-model="row['fornecedor.forCod']"
              :url="`${basePath}/app/fornecedores/get-by-id`" :tela="49"
              :number="true" field-cod="forCod" field-des="pessoa.nome"
              @change="(f) => row['fornecedor.pessoa.nome'] = f?.pessoa?.nome || ''"></s-foreigninput>
            <span v-else>
              {{ item['fornecedor.forCod'] }} -
              {{ item['fornecedor.pessoa.nome'] }}
            </span>
          </div>
        </div>

        <div v-else-if="column.value === 'valor'" style="width: 100px;">
          <s-numberinput v-if="row?.id == item.id" v-model="row.valor"
            @change="(event) => calcular(event, row, false)"
            style="width: 100px;"></s-numberinput>
          <span v-else>
            {{ parseFloat(item.valor || 0).roundNumber(2) }}
          </span>
        </div>
        <div v-else-if="column.value === 'percentual'" style="width: 100px;">
          <s-numberinput v-if="row?.id == item.id" v-model="row.percentual"
            @change="(event) => calcular(event, row, true)"
            style="width: 100px;" @blur="aplicar"></s-numberinput>
          <span v-else>
            {{ parseFloat(item.percentual || 0).roundNumber(2) }}
          </span>
        </div>
        <div v-else style="width: 80px;">
          <div v-if="row?.id != item.id">
            <i title='Excluir' class="s-icon mdi mdi-18px mdi-trash-can-outline"
              @click="excluir(index)"></i>
            <i class="s-icon mdi mdi-18px mdi-pencil"
              @click="editar(index)"></i>
          </div>
          <div v-else>
            <i class="s-icon mdi mdi-18px mdi-close-thick"
              @click="cancelar"></i>
            <i class="s-icon mdi mdi-18px mdi-check-bold" @click="aplicar"></i>
          </div>
        </div>
      </template>
    </s-data-table>

    <div style="text-align: end; margin: 10px;">
      <label style="">Total: </label>
      <span style="width: 100px; font-weight: bold;">
        {{ parseFloat(totalGeral).roundNumber(2) }}%
      </span>
    </div>
  </div>

</template>
<script>
export default {
  name: "s-centro-custo",
  inject: ["hdl"],
  props: {
    value: Array,
    tipoConta: String,
    origem: String,
    total: Number,
  },
  data() {
    return {
      basePath: globalThis.Siggma.basePath,
      row: null,
      totalGeral: 0,
      columns: [
        { text: "Centro de custo", value: "centroCusto" },
        { text: "Nome", value: "pessoa" },
        {
          text: "Valor",
          value: "valor",
          width: 100,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          text: "Percentual",
          value: "percentual",
          width: 100,
          minWidth: 100,
          maxWidth: 100,
        },
        { width: 80, minWidth: 80, maxWidth: 80 },
      ],
    };
  },
  computed: {
    isPagar() {
      return this.tipoConta === "P";
    },
    isReceber() {
      return this.tipoConta === "R";
    },
  },
  watch: {
    value(v) {
      this.totalGeral = 0;
      v.forEach((row) => {
        !row.id && (row.id = `uuid${Math.uuid(8)}`);
        this.totalGeral += parseFloat(row.percentual || 0);
      });
    },
  },
  methods: {
    aplicar() {
      if (!this.row["centroCusto.cencusCod"]) {
        globalThis.Notify.warning("Informe o centro de custo");
        return;
      }
      if (this.row.percentual <= 0) {
        globalThis.Notify.warning("Informe um percentual maior que zero");
        return;
      }

      const row = JSON.parse(JSON.stringify(this.row));
      const data = JSON.parse(JSON.stringify(this.value));
      const index = data.findIndex((v) => v.id === row.id);

      (index !== -1) ? (data[index] = row) : data.push(row);

      this.$emit("input", data);

      this.row = null;
    },
    cancelar() {
      if (this.row["centroCusto.cencusCod"]) {
        this.row = null;
        return;
      }

      const index = this.value.findIndex((v) => v.id === this.row.id);

      this.excluir(index);

      this.row = null;
    },
    novo() {
      this.row = {
        id: `uuid${Math.uuid(8)}`,
        "centroCusto.cencusCod": null,
        "centroCusto.cencusDes": null,
        "cliente.cliCod": null,
        "cliente.pessoa.nome": null,
        "fornecedor.forCod": null,
        "fornecedor.pessoa.nome": null,
        origem: this.origem || null,
        valor: 0,
        percentual: 0,
      };

      const row = JSON.parse(JSON.stringify(this.row));
      const data = JSON.parse(JSON.stringify(this.value));
      data.push(row);

      this.$emit("input", data);
    },
    editar(index) {
      this.row = JSON.parse(JSON.stringify(this.value[index]));
    },
    excluir(index) {
      const data = JSON.parse(JSON.stringify(this.value));
      data.splice(index, 1);
      this.$emit("input", data);
    },
    calcular(event, row, percentual) {
      const valor = parseFloat(event?.target?.value || 0);
      if (percentual) {
        row.valor = (valor * this.total / 100).roundNumber(2, true);
        return;
      }

      row.percentual = (valor * 100 / this.total).roundNumber(2, true);
    },
  },

  mounted() {
    if (this.tipoConta === undefined) {
      const index = this.columns.findIndex((v) => v.value === "pessoa");
      this.columns.splice(index, 1);
    }

    if (this.total === undefined) {
      const index = this.columns.findIndex((v) => v.value === "valor");
      this.columns.splice(index, 1);
    }
  },
};
</script>