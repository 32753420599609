<template>
  <div class="s-report-filters">
    <table style="width: 100%;">
      <tr>
        <td>
          <select ref="logica" v-model="logica" required>
            <option v-for="(val, key) in logicas" :value="`${key}`" :key="key">
              {{val}}
            </option>
          </select>
        </td>
        <td style="text-align: right;">
          <label>Campo:</label>
        </td>
        <td>
          <select ref="coluna" v-model="coluna" required>
            <option v-for="(val, key) in colunms" :value="`${key}`" :key="key">
              {{val}}
            </option>
          </select>
        </td>
        <td style="text-align: right;">
          <label>Condição:</label>
        </td>
        <td>
          <select ref="condicao" v-model="condicao" required>
            <option v-for="(val, key) in condicoes" :value="`${key}`"
              :key="key">{{val}}</option>
          </select>
        </td>
        <td style="text-align: right;">
          <label>Valor:</label>
        </td>
        <td>
          <input v-model="valor" style="width: 150px;">
        </td>
        <td>
          <button type="button" class="btn-small" @click="add()">
            <i class="mdi mdi-plus mdi-18px"></i>
          </button>
        </td>
        <td>
          <button type="button" class="btn-small" @click="clear()">
            <i class="mdi mdi-eraser mdi-18px"></i>
          </button>
        </td>
      </tr>
    </table>
    <el-table v-if="internalValue && internalValue.length" :data="internalValue"
      border style="width: 100%" :max-height="maxHeight">
      <el-table-column prop="logical" label="Lógica"
        width="150"></el-table-column>
      <el-table-column prop="colunm" label="Campo"></el-table-column>
      <el-table-column prop="conditional" label="Condição"
        width="150"></el-table-column>
      <el-table-column prop="value" label="Valor"></el-table-column>
      <el-table-column label="" width="70">
        <template v-slot="scope">
          <button type="button" class="btn-small" @click="del(scope.$index)">
            <i class="mdi mdi-minus mdi-18px"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "s-report-filters",

  props: {
    value: Array,
    colunms: Object,
    maxHeight: Number,
  },

  data() {
    return {
      valor: "",
      coluna: "",
      logica: "and",
      condicao: "",
      logicas: {
        "and": "E",
        "or": "OU",
      },
      condicoes: {
        "ilike '%?%'": "Contém",
        "ilike '?%'": "Inicia",
        "ilike '%?'": "Termina",
        "< '?'": "Menor",
        "= '?'": "Igual",
        "> '?'": "Maior",
        ">= '?'": "Maior ou Igual",
        "<= '?'": "Menor ou Igual",
      },
    };
  },

  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    add() {
      if (!this.coluna) {
        Notify.warning("Informe o campo");
        this.$refs.coluna.focus();
        return;
      }
      if (!this.condicao) {
        Notify.warning("Informe a condição");
        this.$refs.condicao.focus();
        return;
      }

      const logica = this.internalValue.length ? this.logica : "";

      this.internalValue.push({
        value: this.valor,
        colunm: this.colunms[this.coluna],
        logical: logica && this.logicas[logica],
        condition: (" " + logica + " " + this.coluna + " " + this.condicao
          .replace("?", this.valor)),
        conditional: this.condicoes[this.condicao],
      });
      this.$emit("input", this.value);
      this.$refs.logica.focus();
    },

    del(index) {
      this.internalValue.splice(index, 1);
      this.$refs.logica.focus();
    },

    clear() {
      this.internalValue = [];
    },
  },
};
</script>