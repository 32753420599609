const selector =
  ":not([yanda-no-focus]):not([readonly]):not([tabindex=-1]):focusable";

export function getFocusables(container) {
  return jQuery(container).find(selector);
}

export function getFirstFocus(container) {
  const selectables = getFocusables(container);
  return selectables.eq(0);
}

export function getNextFocus(target, container) {
  let nextIndex = 0;
  const selectables = getFocusables(container),
    current = jQuery(target);

  if (current.length === 1) {
    const currentIndex = selectables.index(current);
    if (currentIndex + 1 < selectables.length) {
      nextIndex = currentIndex + 1;
    }
  }

  return selectables.eq(nextIndex);
}

const directive = {
  bind(el, binding) {
    el["_enterNext"] = function (e) {
      if (e.key !== "Enter") {
        return;
      }

      const target = e.target;
      const current = jQuery(target);
      const textarea = target.nodeName === "TEXTAREA" && e.shiftKey === true;
      if (
        current.attr("yanda-no-enter") !== undefined ||
        textarea ||
        target.contentEditable === "true"
      ) {
        return;
      }

      const isButton = target.nodeName === "BUTTON" ||
        ["button", "submit"].includes(target.type);

      !isButton && e.preventDefault();

      const container = binding.value
        ? document.querySelector(binding.value) || el
        : el;

      const next = getNextFocus(target, container);

      const setNext = () => {
        next.focus();
        next.select && next.select();
        next.find(".select2-hidden-accessible") &&
          next.next().find(".select2-selection").focus();
      };

      isButton ? setTimeout(setNext) : setNext();
    };

    el.addEventListener("keypress", el["_enterNext"]);
  },

  unbind(el) {
    if (el["_enterNext"]) {
      el.removeEventListener("keypress", el["_enterNext"]);
      el["_enterNext"] = null;
    }
  },
};

export default directive;
