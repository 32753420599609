import { config as configImport, importModule, require } from "./importModule";
import { controleAcesso, controleAcessoLiberar } from "./controleAcesso";
import Color from "color";
import { Printd } from "printd";
import adapter from "../adapters";
import camposSelecionaveis from "./camposSelecionaveis";
import database from "../database";
import fullCalendarColaboradoresView from "../utils/fullCalendarColaboradoresView";
import historicos from "../utils/petshopHistoricos";
import animais from "../utils/animais";
import { inicializa } from "./toolbar";
import initMenu from "./menu";
import lockSession from "./lockSession";
import mensagens from "./mensagens";
import nfe from "./nfe";
import { sha256 } from "../utils/crypto";
import sharedWorker from "./sharedWorker";
import smart from "./smart";
import toollbarNotify from "./toolbarNotify";
import ufs from "../utils/ufs";
import diff from "../utils/diff";
import roundAbnt from "../utils/roundAbnt";
import itens from "../utils/itens";
import * as documentos from "../utils/documentos";
import initYanda from "./yanda";
import { WsManager } from "./ws";
import colors from "../utils/colors";
import * as bytes from "../utils/bytes";
import visualizarImagem from "../utils/visualizarImagem";

export default {
  Color,
  Notify: toollbarNotify,
  controleAcesso,
  controleAcessoLiberar,
  database,

  // load native modules esm
  import: importModule,

  basePath: document.head.dataset.basepath || "",

  start(options, config) {
    Object.assign(this, options);

    // adaptações gerais, yanda, jquery...
    adapter();

    this.isSmart = ["snoopySmart", "zettaSmart"].includes(config.modulo);
    this.isSnoopySmart = config.modulo === "snoopySmart";
    this.isZettaSmart = config.modulo === "zettaSmart";
    this.freezeSiggmaKeys();

    // TODO: mover para Siggma.info
    this.ufs = ufs.getAll();

    // TODO: mover para Siggma.utils
    this.diff = diff;

    // Quando em modo dev a origin padrao fica igual ao server do webpack. Exemplo: http://localhost:8005.
    // Por isso é necessário alterar o base path para os import ESM
    configImport.basePath = this.devMode
      ? `${document.location.origin}${this.basePath}/js/esm`
      : `${this.basePath}/js/esm`;
    configImport.urlArgs = this.urlArgs;
    globalThis.require = require;

    initYanda(this, config.yanda);
    initMenu(config.menu);
    this._worker = sharedWorker();
    this.ws = new WsManager(this._worker.port);
    lockSession(config);
    this.atualizacoes(null);

    this.import("widgets.js").then(({ default: widgets }) => {
      this.$widgets = widgets;
      this.$widgets.init();
      this.startBind(config);
    });

    inicializa(options);
    mensagens(config);

    options.zettaManager?.temPermissao &&
      options.zettaManager?.abrirAoIniciar && globalThis.Yanda.newApl(23);

    const [defaultAplId, defaultAplParams] = Array.isArray(config.defaultApl)
      ? config.defaultApl
      : [config.defaultApl, null];
    defaultAplId && globalThis.Yanda.newApl(defaultAplId, defaultAplParams);

    if (globalThis.Siggma.isSmart || config.petshopExibirDashboard) {
      globalThis.Yanda.newApl(22);
    }

    if (globalThis.Siggma.isSmart) {
      if (globalThis.Siggma.usuarioRole !== "suporte") {
        globalThis.Yanda.cmd = () => null;
        const elm = globalThis.document.querySelector("#SystemCmd");
        elm && elm.remove();
      }

      // tela pessoas-novo-full => snoopy smart
      const newApl = globalThis.Yanda.newApl;
      globalThis.Yanda.newApl = function (id, params, dad, callback) {
        if (
          ![19, 46, 48, 51, 240, 611].includes(parseInt(id)) ||
          parseInt(dad?.id || 0) === 66
        ) {
          return newApl(id, params, dad, callback);
        }

        switch (parseInt(id)) {
          case 19:
            params = { id: params, tela: "transportadora" };
            break;
          case 46:
            params = { id: params, tela: "cliente" };
            break;
          case 48:
            params = { id: params, tela: "fornecedor" };
            break;
          case 51:
            params = { id: params, tela: "colaborador" };
            break;
          case 240:
            params.tela = "pessoa";
            break;
          default:
            (!params || (typeof params !== "object")) &&
              (params = { id: params, tela: "pessoa" });
            (dad && dad.id == 69) && (params.tela = "cliente");
            break;
        }

        newApl(611, params, dad, callback);
      };
    }
  },

  freezeSiggmaKeys() {
    globalThis.Siggma.filiais = globalThis.Siggma.filiais.map((f) =>
      Object.freeze(f)
    );
    globalThis.Siggma.allFiliais = globalThis.Siggma.allFiliais.map((f) =>
      Object.freeze(f)
    );
    [
      "id",
      "basePath",
      "decimais",
      "empresa",
      "filiais",
      "allFiliais",
      "filial",
      "filialCnpj",
      "isSmart",
      "isSnoopySmart",
      "isZettaSmart",
      "modulo",
      "usuario",
      "usuarioNome",
      "usuarioRole",
    ].forEach((key) =>
      Object.defineProperty(globalThis.Siggma, key, { writable: false })
    );
  },

  startBind(config) {
    if (!this._worker) {
      return;
    }

    this._worker.port.postMessage({
      cmd: "bind-config",
      data: { url: `${this.basePath}/app/bind`, tempo: 90000 },
    });

    if (config.ws) {
      let origin = config.wsOrigin;
      if (/^:\d+/.test(origin)) {
        origin = `${globalThis.location.origin}${origin}`;
      }
      this._worker.port.postMessage({
        cmd: "ws",
        data: {
          cmd: "start",
          params: {
            url: `${origin}/siggma`,
            empresa: this.empresa,
            usuario: this.usuario,
            basePath: globalThis.Siggma.id || globalThis.Siggma.basePath,
            token: globalThis.document.cookie,
          },
        },
      });
      this._worker.port.postMessage({
        cmd: "ws",
        data: {
          cmd: "on",
          params: "notify",
        },
      });
      this._worker.port.postMessage({
        cmd: "ws",
        data: {
          cmd: "on",
          params: "event",
        },
      });
    }
  },

  registerFullCalendarColaboradores() {
    fullCalendarColaboradoresView();
  },

  getContrastYiq(hexcolor) {
    if (!hexcolor) {
      return "black";
    }
    hexcolor = hexcolor.replace(/^#/, "");
    const r = parseInt(hexcolor.substr(0, 2), 16),
      g = parseInt(hexcolor.substr(2, 2), 16),
      b = parseInt(hexcolor.substr(4, 2), 16),
      yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? "black" : "white";
  },

  showNotify(msg) {
    msg &&
      Notify.success(
        msg +
          "<br><br><li>O sistema exibirá/notificará quando for finalizado</li>",
      );
    setTimeout(() => jQuery("#zt-notify .zt-preview").click(), 0);
    this._worker && this._worker.port.postMessage({ cmd: "bind" });
  },

  $$(id) {
    const jans = globalThis.Yanda.getJansById(id);
    return jans ? jans[0] : null;
  },

  autocompletePessoa(params) {
    const isPessoa = params.url === "/app/pessoas/find";
    params.url = globalThis.Siggma.basePath + params.url;

    return {
      info:
        "Pesquise por Código, Nome/Razão Social, Apelido/Nome Fantasia, Telefone ou Celular",
      renderItem(ul, data) {
        const pessoa = isPessoa ? data : data.pessoa;
        return jQuery('<li style="display:flex">').append(
          `<div style="flex: 1 auto; overflow: hidden; white-space: nowrap;
            text-overflow: ellipsis; width: 0; line-height: 25px; border-radius: 4px;">
            <b>${data[params.id || "id"]}</b> -
            ${pessoa.cpfcnpj} - ${pessoa.nome.toUpperCase()}
            ${pessoa.apelidoFantasia ? ` - ${pessoa.apelidoFantasia}` : ""}
          </div>`,
        ).appendTo(ul);
      },
      ...params,
    };
  },

  autocompleteItem(params = {}) {
    params.url = globalThis.Siggma.basePath + (params.url || "/app/itens/find");

    return {
      info:
        "Pesquise por Código, Código Original, Código do Fabricante, GTIN ou Descrição",
      renderItem(ul, data) {
        const nome = data.proNom.toUpperCase();
        const valor = parseFloat(data.proValVen).roundNumber(Siggma.decimais);
        let estoqueInfo = "";

        if (params?.estoque) {
          estoqueInfo = `<i class="mdi mdi-cube-scan mdi-18px"></i> ${
            (parseFloat(data.estoque) || 0).roundNumber(Siggma.decimais)
          }`;

          if (data.medSubItem) {
            const estoqueTitle = (parseFloat(~~data.estoque) || 0)
              .roundNumber(Siggma.decimais);
            estoqueInfo =
              `<span title='Estoque: ${estoqueTitle} ${data.medAbr} e
              ${(data.estoque * parseFloat(data.medQtd)).roundNumber(0)}
              ${data.medSubItem}'>
              ${estoqueInfo}
            <span>`;
          }
        }

        return jQuery('<li style="display:flex">').append(
          `<div style="flex: 1 auto; overflow: hidden; white-space: nowrap;
            text-overflow: ellipsis; width: 0; line-height: 25px; border-radius: 4px;"
            title="${nome} - R$ ${valor} - Estoque: ${data.estoque}">
            <b>${data.proCodOri}</b>
            ${data.proCodFab ? ` - ${data.proCodFab}` : ""}
            ${data.proCodOriginal ? ` - ${data.proCodOriginal}` : ""}
            - ${nome} - <b style="color: #43B9D3">R$ ${valor}</b> - ${estoqueInfo}
          </div>`,
        ).appendTo(ul);
      },
      widgetCss: { width: "600px" },
      ...params,
    };
  },

  sendWhatsapp(params = {}) {
    const { pessoa, message = "", suporte = false, phoneSuporte = "" } = params;

    const hdl = Yanda.newJanela({
      btFe: true,
      title: "Complete as informações e continue",
    });

    const msgDefault = message ? `${message}` : "Digite sua mensagem aqui...";
    const tipoWhatsapp = localStorage.getItem("tipo-whatsapp") ?? false;

    hdl.send = (phone, msg, tipoWhats = false) => {
      const mobile = globalThis.Yanda.util.isMobile()
        ? "api"
        : (tipoWhats ? "api" : "web");

      const sendPhone = `55${phone.replace(/\D/g, "")}`;
      if (sendPhone.length < 12) {
        Notify.warning("Número inválido");
      }
      globalThis.open(
        `https://${mobile}.whatsapp.com/send?phone=${sendPhone}&text=${
          encodeURIComponent(msg)
        }`,
      );
    };

    if (suporte) {
      hdl.send(phoneSuporte, msgDefault);
      return;
    }

    const isPetshop = Siggma.isSnoopySmart || Siggma.modulo == 2,
      height = isPetshop && params.msgAviso ? 165 : 180,
      divMsgVariaveis = isPetshop && params.msgAviso
        ? `<div style="text-align: center;"><label style="font-weight: bold; color: red;">Para trocar as variáveis na mensagem, selecione um atendimento</label></div>`
        : "";

    hdl.setContent(
      `<div id="container">
        <div id="body">
          <div id="phone" ref="refPhone" style="margin-top: 10px; text-align: center;">
            <s-select2
              style="width: 480px;"
              url="${Siggma.basePath}/app/pessoas/get-phones-by-id?id=${pessoa}"
              v-model="phoneTst"
              field-cod="id"
              field-des="telefone"
              :set-all-values="true"
              tags
              placeholder="Telefone/Celular"
            />
          </div>
          <div id="mensagemPadrao" style="margin-top: 10px">
            <s-select2
              v-model="mensagemPadrao"
              style="width: 480px;"
              url="${Siggma.basePath}/config/mensagens-padroes/find-by-term"
              tela="604"
              tela-search="603"
              field-cod="id"
              field-des="descricao"
              :payload="getTipo"
              placeholder="Mensagem Padrão"
            />
          </div>
          ${divMsgVariaveis}
          <div style="margin-top: 10px">
            <textarea v-model="mensagem" ref="refMsg" style="width: 480px;
              height: ${height}px;">
            </textarea>
          </div>
        </div>
        <div id="footer">
            <s-onoff v-if="!isMobile" @change="changeWhatsType" :value="whatsType" active-text="WhatsApp Desktop" inactive-text="WhatsApp Web"></s-onoff>
            <button @click="continuar" id="btnContinuar" style="float: right;" class="btn-primary">Continuar</button>
            <button id="btnCancelar" style="float: right;">Cancelar</button>
        </div>
      </div>`,
    );

    hdl.setTamanho(500, 370);
    hdl.centraliza();

    new Vue({
      el: hdl.$("container"),
      data: {
        mensagem: "",
        phoneTst: "",
        mensagemPadrao: "",
        whatsType: false,
        isMobile: false,
      },
      provide: { hdl },
      mounted() {
        this.mensagem = msgDefault;
        this.whatsType = tipoWhatsapp == "true" ? true : false;
        this.isMobile = globalThis.Yanda.util.isMobile();
      },
      watch: {
        async mensagemPadrao(d) {
          if (!d) {
            this.mensagem = msgDefault;
            return;
          }

          if (!d.mensagem) {
            d.mensagem = await hdl.http(
              `${Siggma.basePath}/config/mensagens-padroes/get-by-id?id=${d.id}`,
            ).then(({ data }) => data.mensagem);
          }

          this.mensagem = d ? `${d.mensagem}` : msgDefault;
          params && (this.mensagem = this.mensagem.replace(
            /\{\{(.*?)\}\}/g,
            (match, grupo) => this.getValor(grupo, match),
          ));
          this.$refs.refMsg.focus();
        },
      },
      methods: {
        changeWhatsType(v) {
          this.whatsType = v;
          localStorage.setItem("tipo-whatsapp", this.whatsType);
        },
        getValor(chave, match) {
          const vars = chave.trim().split(".");
          let result = chave;

          for (let i = 0; i < vars.length; i++) {
            const v = vars[i];
            if (![undefined, null].includes(params[v])) {
              result = params[v];
              continue;
            }
            return match;
          }
          return result;
        },
        continuar() {
          const sendPhone = `55${this.phoneTst.replace(/\D/g, "")}`;
          if (sendPhone.length < 12) {
            Notify.warning("Número informado possui tamanho inválido");
            return;
          }
          hdl.send(this.phoneTst, this.mensagem, this.whatsType);
        },
        getTipo() {
          return { tipo: params.tipoMensagem };
        },
      },
    });

    hdl.$("btnCancelar").addEventListener("click", () => hdl.close());
    hdl.show();
  },

  gerarPdf(hdl, data) {
    (typeof data === "object")
      ? (data.modulo = Siggma.modulo)
      : (data += `&modulo=${Siggma.modulo}`);

    const Yanda = globalThis.Yanda;
    hdl.http({
      url: `${Siggma.basePath}/app/report/gerar-pdf`,
      callback: true,
      data,
    }).then((xhr) => {
      const resp = JSON.parse(xhr.responseText);
      if (resp.type !== "success") {
        Yanda.message(resp);
        return;
      }
      resp.data
        ? Yanda.newApl(104, { url: resp.data })
        : Siggma.showNotify(resp.msg);
    }).catch(() => null);
  },

  gerarXls(hdl, data, name = "report") {
    const body = Object.entries(data)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&");

    hdl.setCarregando(true);

    globalThis.fetch(`${Siggma.basePath}/app/report/gerar-xls`, {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      }),
      body,
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = globalThis.URL || globalThis.webkitURL;

        const link = globalThis.document.createElement("a");
        link.setAttribute("href", url.createObjectURL(blob));
        link.setAttribute("download", `${name}.xls`);
        link.click();
      })
      .catch(async (blob) => {
        const isJson = blob?.type === "application/json";
        const resp = (isJson ? JSON.parse(await blob?.text()) : null) || {
          type: "warning",
          msg: "Houve um erro ao gerar o relatório",
          detail: [
            "Verifique os parametros e tente novamente.",
            "Caso o erro persistir entre em contato com o suporte técnico.",
          ].join("<br>"),
        };

        globalThis.Yanda.message(resp);
      })
      .finally(() => hdl.setCarregando(false));

    /*
    TEMP: deixado aqui caso ocorra um eventual problema em utilizar o fetch
    TEMP: remover caso correr tudo certo
    hdl.setCarregando(true);

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = async function () {
      if (this.readyState !== 4) {
        return;
      }

      hdl.setCarregando(false);

      if (this.status === 200) {
        const blob = xhr.response;
        const url = globalThis.URL || globalThis.webkitURL;

        const link = globalThis.document.createElement("a");
        link.setAttribute("href", url.createObjectURL(blob));
        link.setAttribute("download", `${name}-${hdl.uniqid}.xls`);
        link.click();
        return;
      }

      if (this.status === 500) {
        const blob = xhr.response;
        const isJson = blob?.type === "application/json";
        const resp = (isJson ? JSON.parse(await blob?.text()) : null) || {
          type: "warning",
          msg: "Houve um erro ao gerar o relatório",
          detail: [
            "Verifique os parametros e tente novamente.",
            "Caso o erro persistir entre em contato com o suporte técnico.",
          ].join("<br>"),
        };

        globalThis.Yanda.message(resp);
      }
    };
    xhr.open("POST", `${Siggma.basePath}/app/report/gerar-xls`, true);
    xhr.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8",
    );
    xhr.setRequestHeader("Content-length", params.length);
    xhr.responseType = "blob";
    xhr.send(params);
    */
  },

  gerarXlsAsync(hdl, data) {
    hdl.http({
      url: `${Siggma.basePath}/app/report/gerar-xls-async`,
      callback: true,
      data,
    }).then((xhr) => {
      const resp = JSON.parse(xhr.responseText);
      (resp.type !== "success")
        ? globalThis.Yanda.message(resp)
        : globalThis.Siggma.showNotify(resp.msg);
    }).catch(() => null);
  },

  atualizacoes(data) {
    const $elm = jQuery("#zt-atualizacoes");
    if (!data) {
      $elm.hide();
      return;
    }

    const msg = !data.atualizar
      ? `<b>-</b> ${data.inicio} <b>à</b> ${data.termino}`
      : `<b>-</b> ${data.atualizar} <b>entre</b> ${data.inicio} <b>às</b> ${data.termino}`;

    $elm.find(".zt-detail-value").html(msg);
    $elm.find("p").html(data.mensagem);

    $elm.show();
  },

  atualizarAgenda() {
    const ref = Siggma.$$(422);
    ref && ref.atualizarAgenda && ref.atualizarAgenda();
  },

  response403(ajax) {
    const Yanda = globalThis.Yanda;
    switch (ajax.responseText) {
      case "Sessão expirou":
      case "Sessão bloqueada":
      case "Expediente encerrado":
        Siggma.lockedSession(ajax.responseText);
        break;

      // Acesso negado

      default:
        Yanda.message({
          type: "error",
          title: "Controle de Acesso",
          msg: ajax.responseText ||
            "Oops. Seu usuário não possui acesso a este conteúdo",
        });
        break;
    }
  },

  async setUsuariosPreferencias(data) {
    return await fetch(
      `${Siggma.basePath}/app/index/set-usuarios-preferencias`,
      {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        }),
        body: new URLSearchParams(data),
      },
    )
      .then((resp) => resp.json())
      .then((resp) => (resp.type !== "success" ? Promise.reject(resp) : resp));
  },

  async getUsuariosPreferencias(data) {
    return await fetch(
      `${Siggma.basePath}/app/index/get-usuarios-preferencias?data=${
        JSON.stringify(data)
      }`,
    )
      .then((resp) => resp.json())
      .then((resp) => (resp.type !== "success" ? Promise.reject(resp) : resp));
  },

  async getFiliaisPreferencias(data) {
    return await fetch(
      `${Siggma.basePath}/app/index/get-filiais-preferencias?data=${
        JSON.stringify(data)
      }`,
    )
      .then((resp) => resp.json())
      .then((resp) => (resp.type !== "success" ? Promise.reject(resp) : resp));
  },

  async setFiliaisPreferencias(data) {
    return await fetch(
      `${Siggma.basePath}/app/index/set-filiais-preferencias`,
      {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        }),
        body: new URLSearchParams(data),
      },
    )
      .then((resp) => resp.json())
      .then((resp) => (resp.type !== "success" ? Promise.reject(resp) : resp));
  },

  async getPreferencias(data) {
    return await fetch(
      `${Siggma.basePath}/app/index/get-preferencias?data=${
        JSON.stringify(data)
      }`,
    )
      .then((resp) => resp.json())
      .then((resp) => (resp.type !== "success" ? Promise.reject(resp) : resp));
  },

  async setPreferencias(data) {
    return await fetch(`${Siggma.basePath}/app/index/set-preferencias`, {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      }),
      body: new URLSearchParams(data),
    })
      .then((resp) => resp.json())
      .then((resp) => (resp.type !== "success" ? Promise.reject(resp) : resp));
  },

  async checkPermission(chave) {
    const resp = await fetch(
      `${Siggma.basePath}/app/auth/check-permission?chave=${chave}`,
    ).then((resp) => resp.json());

    return resp.type !== "success";
  },

  addGoogleMapsApi() {
    if (typeof google !== "undefined" && google.maps) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBN3Pzyy18aBvzbmBJeIYe8-knPo6yoI5o&language=pt-br";
      script.id = "maps-googleapis-com";
      script.addEventListener("load", () => resolve(), { once: true });
      document.head.appendChild(script);
    });
  },

  addGoogleChartsApi() {
    if (typeof google !== "undefined" && google.charts) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://www.gstatic.com/charts/loader.js";
      script.id = "gstatic-charts";
      script.addEventListener("load", () => {
        try {
          // Versão 'current' está ocasionando conflito entre o webfont.js e o require.js
          google.charts.load("46", {
            packages: ["corechart", "table", "bar"],
            language: "pt-br",
          });
          google.charts.setOnLoadCallback(resolve);
        } catch (_e) {
          reject("Não foi possível carregar api GoogleCharts");
        }
      }, { once: true });
      document.head.appendChild(script);
    });
  },

  openManager() {
    fetch(`${Siggma.basePath}/config/usuarios/get-manager-url`)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.type != "success") {
          Yanda.message(resp);
          return;
        }
        const url = resp?.data;
        globalThis.open(url, "_blank");
      })
      .catch(() => {
        Yanda.message({
          type: "warning",
          msg: "Erro ao fazer requisição do Token de acesso",
        });
      });
  },

  async waitForJob(id, options = { timeout: 300_000, signal: null }) {
    const startAt = new Date().getTime();
    let aborted = false;
    while (!aborted) {
      const { data: job } = await fetch(
        `${Siggma.basePath}/app/index/wait-for-job?id=${id}`,
        { signal: options.signal },
      )
        .then((resp) => resp.json());
      if (job) {
        return job;
      }
      if (options.signal.aborted) {
        aborted = true;
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (new Date().getTime() - startAt > options.timeout) {
        aborted = true;
      }
    }
    return null;
  },

  utils: {
    diff,
    colors,
    roundAbnt,
    documentos,
    bytes,
    camposSelecionaveis,
    async fillSelect2ByIds(ids, url, elm) {
      ids?.length &&
        await fetch(`${Siggma.basePath}${url}`, {
          method: "POST",
          body: new URLSearchParams({ ids }),
        })
          .then((resp) => resp.json())
          .then((resp) => {
            const $elm = jQuery(elm);
            $elm.html("");
            (resp.results || []).forEach((r) =>
              $elm.append(new Option(r.text, r.id))
            );
            $elm.val(ids).trigger("change.select2");
          })
          .catch(() => null);
    },
    distanciaEntreDoisPontos(latitude1, longitude1, latitude2, longitude2) {
      const raioTerra = 6371; // raio da Terra em km
      const lat1Rad = parseFloat(latitude1) * (Math.PI / 180);
      const lat2Rad = parseFloat(latitude2) * (Math.PI / 180);
      const deltaLatRad = (parseFloat(latitude2) - parseFloat(latitude1)) *
        (Math.PI / 180);
      const deltaLonRad = (parseFloat(longitude2) - parseFloat(longitude1)) *
        (Math.PI / 180);

      const haversineFormula = (
        Math.sin(deltaLatRad / 2) * Math.sin(deltaLatRad / 2)
      ) +
        (
          Math.cos(lat1Rad) * Math.cos(lat2Rad) *
          Math.sin(deltaLonRad / 2) *
          Math.sin(deltaLonRad / 2)
        );
      const anguloCentral = 2 * Math.atan2(
        Math.sqrt(haversineFormula),
        Math.sqrt(1 - haversineFormula),
      );

      const distancia = raioTerra * anguloCentral;

      return parseFloat(distancia.toFixed(10));
    },
    grid: {
      loadOptions(gridOptions) {
        const str = typeof gridOptions === "string"
          ? gridOptions
          : JSON.stringify(gridOptions);
        return eval("(" + str.replaceAll('"#', "").replaceAll('#"', "") + ")");
      },
      async getOptions(id, extra = {}) {
        return await fetch(
          `${Siggma.basePath}/app/tela-consulta/get-grid-options?id=${id}`,
        )
          .then((resp) => resp.json())
          .then((resp) => this.loadOptions(Object.assign(resp.data, extra)));
      },
    },
    /**
     * Printd - Biblioteca para impressão de documentos.
     * Documentação: https://github.com/joseluisq/printd#readme
     */
    Printd,
    visualizarImagem,
  },

  crypto: {
    sha256,
  },

  // TODO: Mover as chaves abaixo para dentro de info
  smart,
  petshop: {
    historicos,
  },
  nfe,
  itens: { tipos: itens.getTipos() },

  info: {
    itens: { tipos: itens.getTipos() },
    petshop: { animais },
    ufs: ufs.getAll(),
  },
};
